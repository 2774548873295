var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('AActionBar',_vm._b({},'AActionBar',_vm.$attrs,false),[(_vm.isEditingTags)?_c('AAuditItemTagsDialog',{attrs:{"is-open":_vm.isEditingTags,"audit-item-ids":_vm.auditItemIds},on:{"close":function($event){_vm.isEditingTags = false}}}):_vm._e(),(_vm.auditItems[0] && _vm.isEditingNote && _vm.auditItemIds.length === 1)?_c('AAuditItemNoteDialog',{attrs:{"audit-item-id":_vm.auditItemIds[0],"is-open":_vm.isEditingNote},on:{"close":function($event){_vm.isEditingNote = false}}}):_vm._e(),_c('v-tooltip',{attrs:{"content-class":"text-body-2 px-2 py-1","bottom":!_vm.$attrs.detached,"top":_vm.$attrs.detached,"disabled":_vm.$vuetify.breakpoint.mdAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 no-uppercase",attrs:{"text":"","elevation":"0","min-width":"36px","ripple":false,"disabled":!_vm.selectionHasAuditItemsWithSelfAssessmentDisabled ||
            _vm.auditIsBulkUpdating},on:{"click":function($event){return _vm.toggleSelfAssessment(_vm.auditItemIds, true)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center py-1 px-2 text-no-wrap text-body-2"},[_c('v-icon',{class:{ 'mr-1': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-monitor ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.include"))+" ")]):_vm._e()],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ft("views.audit_preparation.include")))])]),_c('v-tooltip',{attrs:{"content-class":"text-body-2 px-2 py-1","bottom":!_vm.$attrs.detached,"top":_vm.$attrs.detached,"disabled":_vm.$vuetify.breakpoint.mdAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 no-uppercase",attrs:{"text":"","elevation":"0","min-width":"36px","ripple":false,"disabled":!_vm.selectionHasAuditItemsWithSelfAssessmentEnabled ||
            _vm.auditIsBulkUpdating},on:{"click":function($event){return _vm.toggleSelfAssessment(_vm.auditItemIds, false)}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center py-1 px-2 text-no-wrap text-body-2"},[_c('v-icon',{class:{ 'mr-1': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-monitor-off ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.exclude"))+" ")]):_vm._e()],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ft("views.audit_preparation.exclude")))])]),_c('v-tooltip',{attrs:{"content-class":"text-body-2 px-2 py-1","bottom":!_vm.$attrs.detached,"top":_vm.$attrs.detached,"disabled":!(_vm.auditItemIds.length > 1) && _vm.$vuetify.breakpoint.mdAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._b({staticClass:"pa-0 no-uppercase",attrs:{"disabled":_vm.auditItemIds.length > 1 || _vm.auditIsBulkUpdating,"text":"","elevation":"0","min-width":"36px","ripple":false},on:{"click":function($event){_vm.isEditingNote = true}}},'v-btn',attrs,false),[_c('div',{staticClass:"d-flex align-center py-1 px-2 text-no-wrap text-body-2"},[_c('v-icon',{class:{ 'mr-1': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-message-text-outline ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.edit_notes"))+" ")]):_vm._e()],1)])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.auditItemIds.length > 1 ? _vm.$ft("views.audit_preparation.bulk_edit_notes_title") : _vm.$ft("views.audit_preparation.edit_notes"))+" ")])]),_c('v-tooltip',{attrs:{"content-class":"text-body-2 px-2 py-1","bottom":!_vm.$attrs.detached,"top":_vm.$attrs.detached,"disabled":_vm.$vuetify.breakpoint.mdAndUp},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 no-uppercase",attrs:{"text":"","elevation":"0","min-width":"36px","ripple":false,"disabled":_vm.auditIsBulkUpdating},on:{"click":function($event){_vm.isEditingTags = true}}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center py-1 px-2 text-no-wrap text-body-2"},[_c('v-icon',{class:{ 'mr-1': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"size":"20","color":"primary"}},[_vm._v(" mdi-tag-outline ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_vm._v(" "+_vm._s(_vm.$ft("views.audit_preparation.edit_tags"))+" ")]):_vm._e()],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ft("views.audit_preparation.edit_tags")))])]),_c('v-tooltip',{attrs:{"content-class":"text-body-2 px-2 py-1","bottom":!_vm.$attrs.detached,"top":_vm.$attrs.detached},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 no-uppercase",attrs:{"text":"","elevation":"0","min-width":"36px","ripple":false,"disabled":_vm.auditIsBulkUpdating},on:{"click":_vm.deselectAll}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex align-center py-1 px-2 text-no-wrap text-body-2"},[_c('v-icon',{class:{ 'mr-1': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"size":"20","color":"error"}},[_vm._v(" "+_vm._s(_vm.auditIsBulkUpdating ? "mdi-loading mdi-spin" : "mdi-close")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("views.audit_preparation.number_selected", { n: _vm.auditItemIds.length, }))+" ")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ft("views.audit_preparation.preselect_deselect_all")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }