




































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AActionBar from "@/components/widgets/AActionBar.vue";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { api as auditApi } from "@/store/modules/audit";
import { Action, Getter } from "vuex-class";
import { idable } from "@auditcloud/shared/lib/types/common";
import { AuditItem } from "@auditcloud/shared/lib/schemas";
import AAuditItemTagsDialog from "@/components/dialogs/AAuditItemTagsDialog.vue";
import AAuditItemNoteDialog from "@/components/dialogs/AAuditItemNoteDialog.vue";
import { AuditItemProperties } from "@/store/modules/audit/types";
@Component({
  components: { AActionBar, AAuditItemTagsDialog, AAuditItemNoteDialog },
})
export default class AManualSelectionActionBar extends Vue {
  isEditingTags: boolean = false;
  isEditingNote: boolean = false;

  @Prop({ type: Array, required: true })
  readonly auditItemIds!: Array<AuditItemWithId["id"]>;

  @Getter(auditApi.getters.getAuditItemsForPreparation, {
    namespace: auditApi.namespace,
  })
  getAuditItems!: idable<AuditItem>[];

  @Getter(auditApi.getters.getAuditItemProperties, {
    namespace: auditApi.namespace,
  })
  getAuditItemProperties!: Map<AuditItemWithId["id"], AuditItemProperties>;

  @Getter(auditApi.getters.getAuditIsBulkUpdating, {
    namespace: auditApi.namespace,
  })
  auditIsBulkUpdating!: boolean;

  @Getter(auditApi.getters.getIsRestrictPreparationToCategories, {
    namespace: auditApi.namespace,
  })
  isRestrictPreparationToCategories!: boolean;

  get auditItems() {
    return this.getAuditItems.filter(auditItem =>
      this.auditItemIds.includes(auditItem.id)
    );
  }

  get selectionHasConsideredAuditItems(): boolean {
    return this.auditItemIds.some(
      id => this.getAuditItemProperties.get(id)?.isConsidered
    );
  }

  get selectionHasUnconsideredAuditItems(): boolean {
    return this.auditItemIds.some(
      id => !this.getAuditItemProperties.get(id)?.isConsidered
    );
  }

  @Action(auditApi.actions.manuallyAddToConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyAddToConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyRemoveFromConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyRemoveFromConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  async toggleConsider(
    auditItemIds: Array<AuditItemWithId["id"]>,
    doConsider: boolean
  ) {
    doConsider
      ? await this.manuallyAddToConsideredAuditItems(auditItemIds)
      : await this.manuallyRemoveFromConsideredAuditItems(auditItemIds);
  }

  deselectAll() {
    this.$emit("deselect-all");
  }
}
