








































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { chunk } from "lodash";
import { api as auditApi } from "@/store/modules/audit";
import {
  Aggregation,
  Bucket,
  Filter,
} from "@auditcloud/shared/lib/utils/filter/types";
import naturalCompare from "natural-compare";
import { Getter } from "vuex-class";
import { nullable } from "@auditcloud/shared/lib/types/common";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";

@Component({
  components: {},
})
export default class AAggregationControl extends Vue {
  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }

  @Prop({
    required: true,
    type: Object,
  })
  aggregation!: Aggregation;

  @Prop({
    default: false,
    type: Boolean,
  })
  disabled!: boolean;

  @Getter(auditApi.getters.getAuditClass, {
    namespace: auditApi.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  get buckets() {
    console.log("this.aggregation.buckets", this.aggregation.buckets);
    return this.aggregation.buckets;
  }

  get notNullBuckets() {
    return this.buckets.filter(bucket => bucket.value !== null);
  }

  needle: string | null = "";
  get searchPlaceholder() {
    return this.$t("views.audit_preparation.preselect_search", {
      aggregationName: this.$ft(this.aggregation.name),
    });
  }

  get translatedBuckets(): Array<Omit<Bucket, "name"> & { name: string }> {
    return this.notNullBuckets.map(bucket => {
      return {
        ...bucket,
        name: this.$ft(bucket.name).trim(),
      };
    });
  }

  get fulltextFilteredBuckets() {
    const searchString = (this.needle ?? "").toLocaleLowerCase().trim();

    if (searchString.length > 0) {
      return this.translatedBuckets.filter(bucket => {
        return bucket.name.toLocaleLowerCase().includes(searchString);
      });
    } else {
      return this.translatedBuckets;
    }
  }

  get sortedBuckets() {
    const buckets = [...this.fulltextFilteredBuckets];
    buckets.sort((lhs, rhs) => {
      const textCompare = naturalCompare(
        lhs.name.toLocaleLowerCase(),
        rhs.name.toLocaleLowerCase()
      );
      if (textCompare === 0) {
        return lhs.resultCount - rhs.resultCount;
      } else {
        return textCompare;
      }
    });
    return buckets;
  }

  get labeledBuckets() {
    const result: Array<{
      bucket?: Omit<Bucket, "name"> & {
        name: string;
      };
      letter?: string;
    }> = [];

    let lastLetter = "";

    return this.sortedBuckets.reduce((p, c) => {
      const currentLetter = c.name.trim().substr(0, 1).toLocaleLowerCase();

      if (lastLetter !== currentLetter) {
        lastLetter = currentLetter;

        return [...p, { letter: currentLetter.toLocaleUpperCase(), bucket: c }];
      } else {
        return [...p, { bucket: c }];
      }
    }, result);
  }

  get chunkedBuckets() {
    const buckets = this.labeledBuckets;

    const chunkedBuckets = chunk(
      buckets,
      Math.ceil(buckets.length / this.nCols)
    );
    if (chunkedBuckets.length < this.nCols) {
      const padding = new Array(this.nCols - chunkedBuckets.length).fill([]);
      chunkedBuckets.push(...padding);
    }
    return chunkedBuckets;
  }

  get nCols() {
    return this.$vuetify.breakpoint.xsOnly
      ? 1
      : this.$vuetify.breakpoint.smOnly
      ? 2
      : 3;
  }

  get displayInIndexMode() {
    const indexModeDisabled =
      this.auditClass?.disablePreselectionAggregationIndexing?.some(
        aggregationId => aggregationId === this.aggregation.id
      );
    return !indexModeDisabled && this.buckets.length > 10;
  }

  get hasNullBucket() {
    return this.buckets.some(bucket => {
      return bucket.value === null && bucket.resultCount > 0;
    });
  }
  get isNullBucketActive() {
    const nullBucket = this.buckets.find(bucket => bucket.value === null);

    return nullBucket?.active ?? false;
  }
  get nullBucketResultCount() {
    const nullBucket = this.buckets.find(bucket => bucket.value === null);

    return nullBucket?.resultCount ?? 0;
  }

  toggleNullBucket(v: boolean) {
    this.toggleFilter(null);
  }

  get areAllBucketsActive() {
    return this.notNullBuckets.every(bucket => {
      return bucket.active === true;
    });
  }

  get isNoBucketActive() {
    return this.notNullBuckets.every(bucket => {
      return bucket.active === false;
    });
  }

  toggleFilter(bucketValue: Filter["value"]) {
    this.$emit("filter-toggled", {
      aggregationId: this.aggregation.id,
      value: bucketValue,
    });
  }
  activateAllBuckets() {
    this.$emit("activate-all", this.aggregation.id);
  }
  deactivateAllBuckets() {
    this.$emit("deactivate-all", this.aggregation.id);
  }
}
