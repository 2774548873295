



































































































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";

import AAggregationControl from "./AAggregationControl.vue";

import { api as auditApi } from "@/store/modules/audit";

import { AuditMetadataDoc } from "@auditcloud/shared/lib/schemas";
import { MinimalTemplate } from "@/store/modules/audit/types";
import { Aggregation, Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { FILTER_DIMENSION } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";
import { dialogRoute, DIALOG_NAMES } from "@/routenames";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";
import { stringifyExceptions } from "@auditcloud/shared/lib/utils/errors";
import { isDevEnv } from "@/initApp";

@Component({
  components: {
    AAggregationControl,
  },
})
export default class AAuditPreselection extends Vue {
  templateChanging: boolean = false;
  errorMsg: string | null = null;
  get errorSnackbar(): boolean {
    return this.errorMsg !== null;
  }
  set errorSnackbar(val: boolean) {
    if (val) {
      this.errorMsg = "";
    } else {
      this.errorMsg = null;
    }
  }

  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }

  @Getter(auditApi.getters.getAuditIsBulkUpdating, {
    namespace: auditApi.namespace,
  })
  auditIsBulkUpdating!: boolean;

  @Getter(auditApi.getters.getAuditMetadata, {
    namespace: auditApi.namespace,
  })
  auditMetadata!: AuditMetadataDoc;

  // Template Selection
  @Getter(auditApi.getters.getPossibleAuditTemplatesForCurrentAudit, {
    namespace: auditApi.namespace,
  })
  possibleTemplates!: MinimalTemplate[];

  @Getter(auditApi.getters.getSelectedTemplateIds, {
    namespace: auditApi.namespace,
  })
  selectedTemplateIds!: Array<MinimalTemplate["id"]>;

  @Action(auditApi.actions.setSelectedTemplateIds, {
    namespace: auditApi.namespace,
  })
  setSelectedTemplateIds!: (
    templateIds: Array<MinimalTemplate["id"]>
  ) => Promise<void>;

  get preparedTemplates() {
    return this.possibleTemplates.sort((lhs, rhs) => {
      if (lhs.name === rhs.name) {
        return 0;
      } else {
        return lhs.name < rhs.name ? -1 : 1;
      }
    });
  }

  get selectedTemplateId() {
    return this.selectedTemplateIds[0] ?? null;
  }

  changeTemplate(templateId?: string | null) {
    this.templateChanging = true;

    const templateIds = templateId ? [templateId] : [];
    console.log("setSelectedTemplateIds: started ...", templateId, templateIds);
    this.setSelectedTemplateIds(templateIds)
      .then(() => {
        console.log(
          "setSelectedTemplateIds: ... done",
          templateId,
          templateIds
        );
        this.templateChanging = false;
      })
      .catch(err => {
        console.error(
          "setSelectedTemplateIds: ... failed",
          templateId,
          templateIds,
          err
        );
        this.errorMsg = stringifyExceptions(err, isDevEnv());
        this.templateChanging = false;
      });
  }

  openAuditInfo() {
    const auditId = this.$router.currentRoute.params.auditId;
    this.$router.push({
      name: dialogRoute(
        this.$router.currentRoute.name ?? "error",
        DIALOG_NAMES.AUDIT_INFO_DIALOG
      ),
      params: {
        auditId,
      },
    });
  }

  // Preselection Filter
  @Getter(auditApi.getters.getPreselectionFilterAggregations, {
    namespace: auditApi.namespace,
  })
  preselectionFilterAggregations!: Aggregation[];

  @Getter(auditApi.getters.getConsideredAuditItemIds, {
    namespace: auditApi.namespace,
  })
  consideredAuditItemIds!: Array<AuditItemWithId["id"]>;

  @Getter(auditApi.getters.getTotalAuditItemCount, {
    namespace: auditApi.namespace,
  })
  totalAuditItemCount!: number;

  get dimensionAggregation(): Aggregation | null {
    const dimensionAggregation = this.preselectionFilterAggregations.find(
      aggregation => aggregation.id === FILTER_DIMENSION
    );
    return dimensionAggregation ?? null;
  }

  get dimensionsBuckets(): Aggregation["buckets"] {
    return this.dimensionAggregation?.buckets ?? [];
  }

  get preselectionFilterAggregationsWithoutDimension() {
    return this.preselectionFilterAggregations.filter(
      aggregation => aggregation.id !== FILTER_DIMENSION
    );
  }

  get hasDimensions() {
    return this.dimensionsBuckets.length > 0;
  }

  @Action(auditApi.actions.togglePreselectionFilter, {
    namespace: auditApi.namespace,
  })
  togglePreselectionFilter!: (filter: Filter) => Promise<void>;

  toggleFilter(filter: Filter) {
    console.log("toggleFilter: started ...", filter);
    this.togglePreselectionFilter(filter)
      .then(() => {
        console.log("toggleFilter: ... done", filter);
      })
      .catch(err => {
        console.error("toggleFilter: ... failed", filter, err);
      });
  }

  @Action(auditApi.actions.setAllPreselectionFilter, {
    namespace: auditApi.namespace,
  })
  setAllPreselectionFilter!: (
    aggregationId: Filter["aggregationId"]
  ) => Promise<void>;

  activateAll(aggregationId: Filter["aggregationId"]) {
    console.log("activateAll: started ...", aggregationId);
    this.setAllPreselectionFilter(aggregationId)
      .then(() => {
        console.log("activateAll: ... done", aggregationId);
      })
      .catch(err => {
        console.error("activateAll: ... failed", aggregationId, err);
      });
  }

  @Action(auditApi.actions.clearPreselectionFilter, {
    namespace: auditApi.namespace,
  })
  clearPreselectionFilter!: (
    aggregationId: Filter["aggregationId"]
  ) => Promise<void>;

  deactivateAll(aggregationId: Filter["aggregationId"]) {
    console.log("deactivateAll: started ...", aggregationId);
    this.clearPreselectionFilter(aggregationId)
      .then(() => {
        console.log("deactivateAll: ... done", aggregationId);
      })
      .catch(err => {
        console.error("deactivateAll: ... failed", aggregationId, err);
      });
  }
}
