



























































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";

import AQuestionListGroups from "@/components/widgets/AQuestionListGroups.vue";
import ASearchFilter from "@/components/widgets/ASearchFilter.vue";

import AAuditPreparationItem from "@/components/widgets/AAuditPreparationItem.vue";
import { api as auditApi } from "../store/modules/audit";
import { api as appApi } from "../store/modules/app";
import {
  WorkflowActionPayload,
  WorkflowActionIds,
  WorkflowAction,
} from "@auditcloud/shared/lib/types/WorkflowActions";
import { AuditItemWithId } from "@auditcloud/shared/lib/utils/audit/types";

import ASquareIconBtn from "@/components/controls/ASquareIconBtn.vue";
import { DIALOG_NAMES } from "@/routenames";
import { AuditMetadataClient } from "@/types/Audit";
import { uniq } from "lodash";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import {
  AuditItemProperties,
  PreparationStates,
} from "@/store/modules/audit/types";

import { QuestionSearchResultMap } from "@auditcloud/shared/lib/types/Audit/types";
import AAuditItemTagsDialog from "@/components/dialogs/AAuditItemTagsDialog.vue";
import AAuditItemNoteDialog from "@/components/dialogs/AAuditItemNoteDialog.vue";
import AAuditPreselection from "@/components/widgets/preparation/AAuditPreselection.vue";
import AManualSelectionActionBar from "@/components/widgets/AManualSelectionActionBar.vue";
import ASelfAssessmentActionBar from "@/components/widgets/ASelfAssessmentActionBar.vue";
import {
  Aggregation,
  Filter,
  FilterUiApi,
} from "@auditcloud/shared/lib/utils/filter/types";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";

@Component({
  components: {
    AQuestionListGroups,
    ASquareIconBtn,
    ASearchFilter,
    AAuditPreparationItem,
    AAuditItemNoteDialog,
    AAuditItemTagsDialog,
    AAuditPreselection,
    AManualSelectionActionBar,
    ASelfAssessmentActionBar,
  },
})
export default class AAuditPreparation extends Vue {
  @Getter(auditApi.getters.getAuditItemProperties, {
    namespace: auditApi.namespace,
  })
  auditItemPropertiesMap!: Map<AuditItemWithId["id"], AuditItemProperties>;

  readonly filterUIApi: FilterUiApi = {
    namespace: auditApi.namespace,
    getters: {
      getAggregations: auditApi.getters.getAuditItemListFilterAggregations,
      getFulltextSearch: auditApi.getters.getFullTextSearch,
    },
    mutations: {
      TOGGLE_FILTER: auditApi.mutations.TOGGLE_FILTER_SETTING,
      SET_FILTERS: auditApi.mutations.SET_FILTERS,
      CLEAR_FILTER_FOR_AGGREGATIONS:
        auditApi.mutations.CLEAR_FILTER_FOR_AGGREGATIONS,
      CLEAR_ALL_FILTERS: auditApi.mutations.CLEAR_FILTER_SETTINGS,
      SET_FULLTEXT_SEARCH: auditApi.mutations.SET_FULLTEXT_SEARCH,
    },
  };

  isEditingTags: boolean = false;
  isEditingNote: boolean = false;
  showExcludedQuestions: boolean = false;

  get isTouchDevice() {
    return matchMedia("(hover: none)").matches;
  }

  @Mutation(auditApi.mutations.TOGGLE_FILTER_SETTING, {
    namespace: auditApi.namespace,
  })
  toggleClickedFilter!: (clickedFilter: Filter) => void;

  @Getter(auditApi.getters.getPossiblePreparationStates, {
    namespace: auditApi.namespace,
  })
  getPossiblePreparationStates!: PreparationStates[];

  @Getter(auditApi.getters.getPreparationState, {
    namespace: auditApi.namespace,
  })
  getPreparationState!: PreparationStates;

  get step(): number | string {
    return (
      this.getPossiblePreparationStates.indexOf(this.getPreparationState) + 1
    );
  }
  set step(val: number | string) {
    const stepName =
      this.getPossiblePreparationStates[parseInt(String(val), 10)];
    if (stepName) {
      this.getPreparationState = stepName;
    }
  }
  mode: string = "grouped";

  @Getter(auditApi.getters.getAuditItemsForPreparation, {
    namespace: auditApi.namespace,
  })
  auditItems!: AuditItemWithId[];

  @Getter(auditApi.getters.getAuditStatus, {
    namespace: auditApi.namespace,
  })
  auditStatus!: string | null;

  @Getter(auditApi.getters.getActiveFilters, {
    namespace: auditApi.namespace,
  })
  activeFilters!: Filter[];

  @Action(auditApi.actions.manuallyAddAuditItemsToSelfAssessment, {
    namespace: auditApi.namespace,
  })
  manuallyAddAuditItemsToSelfAssessment!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyRemoveAuditItemsFromSelfAssessment, {
    namespace: auditApi.namespace,
  })
  manuallyRemoveAuditItemsFromSelfAssessment!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyAddToConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyAddToConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  @Action(auditApi.actions.manuallyRemoveFromConsideredAuditItems, {
    namespace: auditApi.namespace,
  })
  manuallyRemoveFromConsideredAuditItems!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => Promise<void>;

  async toggleIgnore(
    auditItemIds: Array<AuditItemWithId["id"]>,
    ignore: boolean
  ) {
    ignore
      ? await this.manuallyRemoveFromConsideredAuditItems(auditItemIds)
      : await this.manuallyAddToConsideredAuditItems(auditItemIds);
  }

  async toggleIgnoreSelfAssessment(
    auditItemIds: Array<AuditItemWithId["id"]>,
    ignore: boolean
  ) {
    ignore
      ? await this.manuallyRemoveAuditItemsFromSelfAssessment(auditItemIds)
      : await this.manuallyAddAuditItemsToSelfAssessment(auditItemIds);
  }

  @Getter(auditApi.getters.getAuditDocumentData, {
    namespace: auditApi.namespace,
  })
  audit!: AuditMetadataClient | null;

  @Getter(auditApi.getters.getAuditItemListFilterAggregations, {
    namespace: auditApi.namespace,
  })
  aggregations!: Aggregation[];

  @Getter(auditApi.getters.getFullTextSearch, {
    namespace: auditApi.namespace,
  })
  fulltextSearch!: string;

  @Watch("aggregations")
  onAggregationsChanged() {
    this.clearAuditItemSelection();
  }

  @Watch("fulltextSearch", { deep: true, immediate: true })
  onFulltextSearchChanged() {
    this.clearAuditItemSelection();
  }

  @Getter(auditApi.getters.getAuditPermissions, {
    namespace: auditApi.namespace,
  })
  permissions!: AuditPermissions;

  get writePermission() {
    return this.permissions.write;
  }

  get hasPermissionToWorkWithTheList() {
    return this.writePermission && this.isAuditStatePreparation;
  }

  @State(appApi.state.drawerVisible, {
    namespace: appApi.namespace,
  })
  drawerVisible!: boolean;

  @Getter(auditApi.getters.getSearchResultsByAuditItemIds, {
    namespace: auditApi.namespace,
  })
  searchResultsByAuditItemIds!: QuestionSearchResultMap;

  @Getter(auditApi.getters.getAuditIsBulkUpdating, {
    namespace: auditApi.namespace,
  })
  auditIsBulkUpdating!: boolean;

  @Mutation(auditApi.mutations.SET_SELECTED_AUDITITEM_IDS, {
    namespace: auditApi.namespace,
  })
  setSelectedAuditItemIds!: (
    auditItemIds: Array<AuditItemWithId["id"]>
  ) => void;

  @Mutation(auditApi.mutations.TOOGLE_SELECTED_AUDITITEM_ID, {
    namespace: auditApi.namespace,
  })
  toggleAuditItemSelection!: (auditItemId: AuditItemWithId["id"]) => void;

  @Getter(auditApi.getters.getSelectedAuditItemIds, {
    namespace: auditApi.namespace,
  })
  selectedAuditItemIds!: Array<AuditItemWithId["id"]>;

  get isAtLeastOneItemSelected() {
    return this.selectedAuditItemIds.length > 0;
  }

  get isNoAuditItemSelected() {
    return this.selectedAuditItemIds.length === 0;
  }

  get areAllAuditItemsSelected() {
    return this.selectedAuditItemIds.length === this.auditItems.length;
  }

  set areAllAuditItemsSelected(value: boolean) {
    this.setSelectedAuditItemIds(value ? this.auditItems.map(i => i.id) : []);
  }

  clearAuditItemSelection() {
    this.setSelectedAuditItemIds([]);
  }

  @Getter(auditApi.getters.getActionsPerView, {
    namespace: auditApi.namespace,
  })
  actionsPerView!: (viewname: string) => string[];

  @Getter(auditApi.getters.getIsRestrictPreparationToCategories, {
    namespace: auditApi.namespace,
  })
  isRestrictPreparationToCategories!: boolean;

  @Getter(auditApi.getters.getAuditItemProperties, {
    namespace: auditApi.namespace,
  })
  auditItemProperties!: Map<AuditItemWithId["id"], AuditItemProperties>;

  handleWorkflowActions() {
    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.UPLOAD_AGENDA]: {
        id: WorkflowActionIds.UPLOAD_AGENDA,
        text: `${this.$t("views.audit_preparation.upload_agenda")}`,
        icon: "mdi-paperclip",
        handler: () => {
          const newRoute = {
            name: this.$route.name ?? "error",
            params: {
              ...this.$route.params,
              dialogName: DIALOG_NAMES.AUDIT_ATTACHMENTS,
            },
            query: this.$route.query,
          };
          this.$router.push(newRoute);
        },
      },
    };
    const actionsList = this.actionsPerView("preparation")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };
    this.$emit("actions", actions);
  }

  mounted() {
    this.$vuetify.goTo(0);
    this.handleWorkflowActions();
  }

  @Getter(auditApi.getters.getAreMultipleStandardsReferenced, {
    namespace: auditApi.namespace,
  })
  hasMultipleStandardIds!: boolean;

  get isAuditStatePreparation() {
    return this.auditStatus === AuditStatusId.Preparation;
  }
}
